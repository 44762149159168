import http from '../utils/http';

const api = {
    upload(data){
        return http.post(`/operate/vx/feedbacks`,data)
    }
  
   
   
}
export default api;