
<template>
    <div class="feedback-container">
      <!-- 标题 -->
        <van-row>
            <van-col span="24" class="commonStyle">
                <van-cell-group >
                   <van-field v-model="feedback.title"  class="feedbackContact"   placeholder="请输入标题" :label="null" />
                </van-cell-group>
            </van-col>
        </van-row>
        <!-- 输入框 -->
        <van-row>
            <van-col span="24">
                <van-field v-model="feedback.information" border class="feedbackMessage" type="textarea" placeholder="请输入反馈信息" :label="null" />
            </van-col>
        </van-row>
        <!-- 联系方式 -->
        <van-row>
            <van-col span="24" class="commonStyle">
                <van-cell-group >
                   <van-field v-model="feedback.contact"  class="feedbackContact" left-icon="phone-o" type="tel"  placeholder="留下您的联系方式 (手机或邮箱）" :label="null" />
                </van-cell-group>
            </van-col>
        </van-row>
        <!-- 上传图片 -->
        <van-row>
            <van-col span="24" class="commonStyle uploadFile">
              <div class="uploadImgText">上传图片（最多6张）</div>
              <div class="uploadImgConponenet">
                <div>
                    <van-uploader :accept="'image/*'" :after-read="onRead" :before-read="beforeRead"  :max-size="5000 * 1024" @oversize="onOversize" v-model="fileList" multiple :max-count="6" />
                </div>
              </div>
            </van-col>
        </van-row>
        <!-- 提交按钮 -->
        <van-row>
          <van-col :span="24" class="commonStyle feedbackButton">
            <van-button round type="info" style="width:100%" @click="onSubmit"  :loading="loading" loading-text="提交中...">提交</van-button>
          </van-col>
        </van-row>
    </div>
</template>
<script>
import api from '@/api/api.js'
// import qs from 'qs'
export default {
    data(){
      return{
        feedback:{
          information:"",
          contact:"",
          title:""
        },
        fileList:[],
        loading:false
      }
    },
    methods:{
       onRead (file) { // 上传图片到图片服务器
        console.log(file)
      },
      // 限制大小
      onOversize(file) {
        console.log(file);
         this.$toast.fail('文件大小不能超过 500kb');
      },
      // 限制类型
      beforeRead(file) {
        console.log(file.type)
        if (file.type != 'image/jpeg' && file.type != 'image/png') {
          this.$toast.fail('请上传jpg , png格式图片');
          return false;
        }
        return true;
      },
      onSubmit(){
        let val1 = this.feedback.information;
        let val2 = this.feedback.contact;
        let val3 = this.feedback.title;
        if(val1.replace(/(^s*)|(s*$)/g, "").length == 0){
            this.$toast.fail('反馈信息为必填');
        }else if(val2.replace(/(^s*)|(s*$)/g, "").length  == 0){
            this.$toast.fail('联系方式为必填');
        }else if(val3.replace(/(^s*)|(s*$)/g, "").length  == 0){
             this.$toast.fail('标题为必填');
        }else{
           let  fdata = new FormData();
           fdata.append('contactInfo',this.feedback.contact)//联系方式
           fdata.append('content',this.feedback.information) //内容  
           fdata.append('title',this.feedback.title) //标题
           if(this.fileList.length > 0){
             for(let i = 0 ;i < this.fileList.length; i++){
              fdata.append('file',this.fileList[i].file)
            }
           }
           this.loading = true;
            api.upload(fdata).then(res=>{
              this.loading =false;
              if(res.data.code == 0){
                this.$router.push('/about')
              }
            })
        }
      
      }
    }
}
</script>
<style lang="scss" scoped>
  .feedback-container{
    padding: .5rem .5rem;
    .commonStyle{
      padding:.5rem .8rem;
      .uploadImgText{
        color: #9A9A9A;
        font-size: .8rem;
      }     
      .uploadImgConponenet{
        margin-top: 1rem;
      }
    }
    .uploadFile{
      margin-top: 2rem;
    }
    .feedbackButton{
      margin-top: 1rem;
    }
    .feedbackMessage{
        ::v-deep .van-field__control{
            box-shadow: 0px 0px 5px 0px #ccc;
            min-height: 10rem;
            border-radius: .5rem;
            padding: .5rem .6rem;
            margin-bottom: .5rem;
            border: 1px solid #fff;
            -webkit-appearance:none;
          }
        
    }
    .feedbackContact{
      box-shadow: 0px 0px 5px 0px #ccc;
      border-radius: .5rem;
     ::v-deep .van-hairline--top-bottom::after{
        border-width: 0;
      }
     
    }
  }

</style>
